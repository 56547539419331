.logout-msg-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #3d425b;
  height: 190px;
}
.logout-action-btn {
  display: flex;
  margin-top: 30px;
}
