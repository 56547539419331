@import "../../App.scss";

.nodata-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  height: 53vh;
}
.url-text {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.search-bar {
  border: none !important;
}
.text-font-style {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
}
.label-class {
  position: absolute;
  top: 6px;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  /* Primary Text color */

  color: #3d425b;
}
.url-config-wrapper {
  height: 80vh;
  padding-top: 15px;
   overflow-y: scroll;
}

// url config row style

.table-header-urltype {
  width: 9rem;
}

.table-header-urldesc {
  width: 25rem;
  // min-width: 25rem;
}
.table-header-url {
  width: 20rem;
  // min-width: 15rem;
}
.table-header-urlrole {
  width: 25rem;
  // min-width: 15rem;
}
.rowClasses .table-url-desc[data-title]:hover:after {
  content: "this new title";
  position: absolute;
  top: -100%;
  left: 0;
}

  .table-url-desc{
    height: 50px !important;
    width: 120px !important;
    border: none !important;
    box-shadow: 5px !important;
  }
.url-config-container{
  display:  "flex";
}

@media screen and (max-width:768px) {
  .search-bar{
    margin: 0px !important;
  }
  .url-config-wrapper{
    overflow-y: unset;
    overflow-x: scroll;
  }
}


@media screen and (max-width:414px) {
  .url-config-container{
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
  .search-bar {
    // width: 360px !important;
    margin: 0px;
  }
  .label-class{
    position: unset;
  }
  .table-header-url{
    width: 30rem;
  }
  .url-config-wrapper{
    padding-top: 10px;
    overflow-y: scroll;
  }
  .table-header-urlrole{
     width: 160px !important; 
  }
}
