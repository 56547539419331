@import "../../../App.scss";

$linear-gradiant: linear-gradient(to right, #1a60a5f6 0%, #d4d4f1 90%);
$primary-color: #2a74a5;
$white: #fff;

#qdata-table-wrapper {
  .react-bootstrap-table th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
  }
  .react-bootstrap-table th .caret-4-desc:after {
    content: "\2193";
  }
  .react-bootstrap-table th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
  }
  .react-bootstrap-table th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
  }
  .react-bootstrap-table th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
  }
  .react-bootstrap-table th .order-4:after {
    content: "\2193";
    opacity: 0.4;
  }
  .pagination {
    justify-content: flex-end;
  }
  .icon-color {
    color: $primary-color;
  }
  .table {
    --bs-table-accent-bg: $table-color;
    --bs-table-bg: $table-color;
  }
  .table > thead {
    background: #f5f5f5;
  }
  .table-striped > tbody > tr:nth-of-type(even) {
    background: $table-color !important;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    background: #fff !important;
  }
  .react-bootstrap-table {
    border-radius: 8px;
    border: 1px solid #eeeeee;
  }

  // modal field
  .field-wrapper {
    margin-bottom: 20px !important;
  }
  .sortable {
    cursor: pointer;
  }
}
// manage table header width
.user-thead {
  width: 20rem;
  // min-width: 25rem;
}
.assign-user {
  width: 70rem;
}

.desc-tab {
  width: 70rem;
  max-width: 38rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-font-style {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  height: 50px !important;
}

.modal-header {
  background: linear-gradient(90.42deg, #0a619a 0.37%, #ffffff 107.24%);
  color: $white;
  height: 40px;
}
.search-bar {
  border-radius: 0.25rem !important;
  background: #f5f5f5 !important;
  border: none !important;
  height: 35px !important;
  // width: 300px !important;
  &::placeholder {
    font-family: "FontAwesome";
  }
}
.search-btn {
  border-radius: 0rem 0.25rem 0.25rem 0rem !important;
  background: $primary-color !important;
  color: $white !important;
  box-shadow: none !important;
  height: 35px;
}
.add-btn {
  background: $primary-color !important;
  color: $white !important;
  border-radius: 0.25rem !important ;
  padding-bottom: 0.5rem;
  width: 160px;
  height: 35px;
  margin-left: 8px !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
}
.bulk-delete {
  border-radius: 0.25rem !important ;
  padding-bottom: 0.5rem;
  width: 160px;
  height: 35px;
  margin-left: 1.25rem !important;
  color: $white !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
}
.table-container {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}
.search-box {
  display: flex;
  justify-content: flex-end;
}
.nodata-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58vh;
}
.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-btn {
  margin: 0rem 1.2rem;
  border: 1px solid $primary-color !important;
  color: $primary-color !important;
  background: $white;
  font-weight: 700;
  line-height: 1rem !important;
  width: 100px !important;
  padding: 0.54rem 0.9rem !important;
}
.submit-btn {
  background-color: $primary-color !important;
  color: $white !important;
  width: 100px !important;
  padding: 0.54rem 0.9rem !important;
  line-height: 1rem !important;
}
.table-roles {
  .chips-card {
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    background-color: #bdbdbd;
    line-height: 19px;
  }
}

.rowClasses,
.headerwrapper {
  height: 50px !important;
}
.rowClasses > td:not(:first-child) {
  max-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.8rem !important;
  // .table-url-desc{
  //   height: 50px !important;
  //   background-color: #BDBDBD;
  //   color: #333;
  // }
}
.headerwrapper {
  max-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-msg-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #3d425b;
  height: 190px;
}
.delete-action-btn {
  display: flex;
  margin-top: 30px;
}
// -------pagination----------/

.pagination-box {
  margin-top: 15px;
  float: right;
}
// .page-item{
//   margin: 5px !important;
// }
.pagination-box {
  border: none;
  // padding-top:;
}
.page-link {
  border: none !important;
  background: #fff !important;
}
.page-item.active .page-link {
  background: #2a74a5 !important;
}
.pagination-content {
  text-align: center;
  color: #bdbdbd;
}
.page-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 30px;
  border-radius: 2px;
}
.active-page-list {
  background: #2a74a5;
}
.pagination-btn {
  background: #f5f5f5;
  margin: 0px 4px;
  border-radius: 2px;
}
.form-control {
  border: 1px solid transparent !important;
  width: 95% !important;
  margin-left: 11px;
  &:focus {
    border: 1px solid #2a74a5 !important;
  }
}
.form-control.is-valid,
.was-validated .form-control:valid,
.custom-select:valid,
.custom-select.is-valid {
  border-color: transparent !important;
  padding-right: calc(1.5em + 0.9rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #ff0707 !important;
  padding-right: calc(1.5em + 0.9rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff0707 !important;
}
.invalid-feedback {
  color: #ff0707 !important;
  position: absolute;
  left: 27px !important;
}
table {
  overflow-x: scroll;
}
.selection-cell-header {
  width: 1.6rem !important;
}
// .close-icon {
//   display: flex;
//   align-items: center;
//   height: 42px;
//   position: relative;
//   // justify-self: flex-end;
// }
.url-tab-action {
  width: 0.3rem;
}
.input-text {
  background: #f5f5f5 !important;
}
.url-tab-view {
  display: flex;
  position: relative;
}
// searchbox icon with robot style

.table-wrapper {
  position: relative;
  height: 42px;
}
.search-menu-table-icon {
  position: absolute;
  top: 7px;
  left: 22px;
  color: #3d425b;
  margin-left: 7px;
}

.table-search {
  &::placeholder {
    position: absolute;
    font-family: "Roboto";
    left: 35px;
    top: 10px;
  }
}
.table-close-icon {
  position: relative;
  float: right;
  bottom: 29px;
  right: 8px;
}

.checkbox-wrapper > label {
  padding-left: 5px;
  margin-top: 7px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #797979;
}
.qtabs-wrapper {
  padding-top: 20px;
}
.checkbox-wrapper {
  padding-top: 5px;
}
.checkbox-content {
  background: #ffffff !important;
  border: 2px solid #bdbdbd !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.file-upload-wrapper {
  float: right !important;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-top: 10px;
  position: absolute;
  right: 15px;
  .file-text {
    padding: 5px;
  }

  .inputfile-box {
    position: relative;
    width: 280px;
    height: 30px;
    margin: 0px 15px;
  }

  .inputfile {
    display: none;
  }

  .container {
    display: inline-block;
    width: 100%;
  }

  .file-box {
    font-size: 0.9rem;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    padding: 5px 0px 5px 5px;
    box-sizing: border-box;
    height: calc(2rem - 2px);
    border-radius: 5px 0px 0px 5px;
    background: #f5f5f5;
  }

  .file-button {
    background: #2a74a5;
    color: $white;
    border-radius: 0px 5px 5px 0px;
    padding: 8px;
    width: 60px;
    height: 30px;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
  .upload-btn {
    background: #2a74a5;
    color: $white !important;
    padding: 8px;
    height: 30px;
    border-radius: 5px;
    width: 90px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }
  .file-container{
    display: flex;
  }
}

@media screen and (max-width: 1366px) {
  .url-tab-view {
    display: flex;
  }
  .delete-msg-content {
    margin-left: 22px;
  }
  // .qtextinput-wrapper{
  // width: 321px;
  // }
}

@media screen and (min-width: 1367px) {
  .qtextinput-wrapper {
    // width: 321px;
    .search-bar {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .table-container {
    flex-direction: column;
    align-items: flex-end;
    top: -30px !important;
  }
  .qtabs-wrapper {
    margin-top: 110px;
  }
  .qtextinput-wrapper {
    padding-bottom: 10px !important;
    // width: 321px;
    .search-bar {
      width: 100%;
    }
  }
  .close-icon {
    // top: -50px ;
    margin-top: -51px;
    margin-bottom: 8px;
  }
  .delete-msg-content {
    margin-left: 18px;
  }
  .search-bar {
    width: 100% !important;
  }
  .search-menu-table-icon {
    left: 4px;
  }
  .table-close-icon {
    top: 12px;
    right: 0px;
  }
  .url-tab-view {
    padding-top: 9px;
  }
  //  .checkbox-wrapper{
  //   position: absolute;
  //   right: 195px;
  //  }
  .file-upload-wrapper {
    top: 100px;
  }
  .upload-btn {
    margin-top: 0px;
  }
}

@media (max-width: 414px) {
  #qdata-table-wrapper {
    .react-bootstrap-table {
      .table {
        width: 400px !important;
      }
    }
    // .rowClasses > td{
    //   width: 100%;
    //   max-width: 100vw;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
  }
  .user-tab {
    .title-container {
      .management-label {
        position: absolute;
        top: 66px;
        // z-index: 100;
        font-family: Roboto;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 16px !important;
        line-height: 19px !important;
      }
    }
    .qtabs-wrapper {
      margin-top: 160px !important;
      .table-container {
        top: -20px !important;
      }
    }
    .file-upload-wrapper {
      top: 108px;
      .file-container {
        display: flex !important;
        margin-top: 0px;
        .upload-btn {
          margin-top: 0px !important;
        }
        .inputfile-box {
          width: 245px;
          padding-right: 10px;
        }
      }
      .file-text{
        padding: 5px;
      }
    }
  }

  .group-tab {
    .qtabs-wrapper {
      margin-top: 85px;
    }
    .management-label {
      position: absolute;
      top: 60px;
      font-family: Roboto;
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
  }

  .qtabs-wrapper > .tab-content {
    padding-top: 10px !important;
  }

  .qtabs-wrapper {
    margin-top: 220px;
  }
  .qtabs-wrapper > .tab-content {
    padding-top: 10px !important;
  }
  .search-bar {
    width: 100% !important;
  }
  .search-box {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .add-btn {
    float: right;
  }
  .table-container {
    flex-direction: column;
    padding-top: 15px;
  }
  .admin-search {
    width: 100% !important;
  }
  .search-menu-table-icon {
    //  top: 27px;
    left: 8px;
  }
  .table-close-icon {
    top: 12px;
  }
  .url-tab-view {
    padding-top: 9px;
  }
  .checkbox-wrapper {
    position: unset;
  }
  .file-upload-wrapper {
    right: 0px;
    top: 130px;
    flex-direction: column;
  }
  .file-text {
    align-self: flex-end;
  }
  .inputfile-box {
    margin: 0px !important;
    padding-bottom: 15px;
  }
  .upload-btn {
    width: 90px;
    align-self: end;
    margin-top: 10px;
  }
  .file-button{
    width: 122px !important;
    text-align: center !important;
  }
}
th {resize:horizontal; overflow:auto;}