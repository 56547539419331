@import '../../App.scss';

.main-footer{
//  border: red solid;
display: flex;
justify-content: space-between;
align-items: center;
position: absolute;
bottom: 0;
width: 100%;
width: -moz-available;          /* WebKit-based browsers will ignore this. */
width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
width: fill-available;
height: 50px;
// padding: 0.8em;
// margin-top: 4px;  
border-top: 1px solid rgb(201, 201, 201);
background: $white;
z-index: 999;
}
