.audit-label {
  padding: 10px 0px;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #3d425b;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  // margin-top: 2.375rem;
  padding-top: 15px;
  padding-bottom: 12px;
}
.report-wrapper{
  overflow-y: scroll;
  height: 80%;
}
.report-closeIcon {
  float: right;
  position: relative;
  // top: 10px;
  bottom: 29px;
}

.search-wrapper {
  position: relative;
  height: 42px;
}
.search-menu-report-icon {
  position: absolute;
  top: 7px;
  left: 10px;
  color: #3d425b;
  margin-left: 7px;
}

.report-search {
  width: 100% !important;
  margin-left: 0px;
  &::placeholder {
    position: absolute;
    font-family: "Roboto";
    left: 35px ;
    top: 10px;
  }
}

.search-wrapper > .qtextinput-wrapper {
  width: 100%;
}

@media screen and (max-width: 1366px) {
  .report-closeIcon {
    left: 4px;
  }
}

@media screen and (max-width: 768px) {
  .report-closeIcon {
    float: right;
    bottom: 40px;
  }
  .report-wrapper {
    height: 80vh;
    overflow-y: scroll;
  }
  .search-menu-icon {
    left: 7px;
  }
  .search-menu-report-icon {
    left: 10px;
  }
  .header-wrapper{
    padding-top: 15px;
  }
}

@media screen and (min-width: 769px) {
  .report-wrapper {
    // overflow-y: unset;
    .search-bar {
      // width: 321px;
    }
  }
}

@media screen and (max-width: 414px) {
  .header-wrapper {
    flex-wrap: nowrap;
    flex-direction: column;
    margin-top: 10px;
  }
  .header-wrapper > div {
    margin-bottom: 1rem;
  }
  .audit-label {
    align-self: flex-start;
  }
  .cfs-list {
    width: 100% !important;
  }
  .report-wrapper {
    height: 80vh;
    overflow-y: scroll;
  }
  .header-wrapper {
    margin-top: 0px;
    padding-top: 10px;
  }
  .search-menu-report-icon {
    left: 10px !important;
    // top: 30px;
  }
  .report-closeIcon {
    bottom: 39px;
  }
}
