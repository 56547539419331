.help-support {
  overflow-y: scroll;
  height: 80%;
  .search-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    padding: 0px !important;
    .qtextinput-wrapper {
      input {
        height: 35px !important;
      }
      .input-text {
        background: #f5f5f5 !important;
      }
    }
    .help-search-input {
      width: 100% !important;
      margin: 0;
    }
    .qbutton-wrapper {
      .search-btn {
        height: 42px;
        background: #2a74a5;
        color: #fff;
      }
    }
  }
}

.search-wrapper,
.help-support-close-icon {
  position: relative;
}
.search-menu-help-icon {
  position: absolute;
  top: 7px;
  left: 10px;
  color: #3d425b;
  margin-left: 7px;
}

.help-search-input {
  &::placeholder {
    position: absolute;
    font-family: "Roboto";
    left: 35px;
    top: 10px;
  }
}

.help-support {
  padding: 15px;
  .help-support-wrapper{
      padding-bottom: 15px;
  }
  .help-support-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #3D425B;
  }
}

.help-support-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 1366px) {
  .help-support-close-icon {
    z-index: 100;
    position: relative;
    top: 7px;
  }
}

@media screen and (min-width: 1367px) {
  .help-support-close-icon {
    position: relative;
    top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .help-support {
    top: 50px;
    .help-search-input {
      width: 100%;
    }
  }
  .help-support-close-icon {
    right: 0px;
  }
  .search-menu-help-icon {
    left: 10px;
  }
  
}

@media screen and (max-width: 414px) {
  .qtextinput-wrapper {
    width: 100% !important;
  }
  .help-support {
    top: 70px;
    width: 100%;
    .help-search-input {
      width: 100% !important;
    }
  }
   .help-support-close-icon {
    right: 0px;
  }
  .help-support-container{
    flex-direction: column;
  }
}
