@import "../../../App.scss";

// .qtabs-wrapper {
//   max-width: fit-content;
// }
.nav-tabs.nav-bordered a.active {
  border-radius: 10px 10px 0px 0px;
  color: $white;
  background: $primary-color;
  width: 160px;
  height: 35px;
  text-align: center;
  border-bottom: none !important;
}
.nav-tabs.nav-bordered a {
  border-radius: 10px 10px 0px 0px;
  color: $primary-text-color;
  background: #f5f5f5;
  width: 160px;
  height: 35px;
  text-align: center;
  margin: 2px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.nav-bordered {
  margin-bottom: 20px !important;
}

@media (max-width: 415px) {
  .qtabs-wrapper > nav {
    // max-width: fit-content;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    margin: 0px !important;
  }
  .nav-tabs.nav-bordered a.active ,.nav-tabs.nav-bordered a{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding: 0px !important;
  }
}
