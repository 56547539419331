.management-wrapper{
    
  overflow-y: scroll;

.user-header {
  width: 10rem;
}
.img-container{
    position: absolute;
    left: 3.4rem;
    top: 1.3rem;
    height: 30px;
    width: 30px;
}
.toast-text{
    position:relative;
    width: 30px;
    left: 6rem;
    top: 0px;
   
}
.table-container{
  position: absolute;
  top: -44px;
  right: 15px;
  width: 100%;
}
.title-container{
  margin-left: 15px;
  margin-top: 15px;
  .management-label{
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #3d425b;
  }
}
.page-wrapper{
  padding-top: 0px !important;
}

.starter-container{
  padding: 0px;
}
.starter-page-content{
  padding: 0px;
}
.starter-container{
display: none !important;
}
}
.assign-user{
  min-width: 20rem;
}
.role-text{
  width: 150px;
  min-width: 150px;
}
.user-id-text{
  font-size: 12px;
}
.desc-tab{
  min-width: 24rem;
}
.user-manage-wrapper{
  padding-top: 15px;
}
@media screen and (max-width:1366px) {
  .table-container{
    top: -27px !important; 
  }
}
@media screen and (min-width:1367px) {
  .table-container{
    top: -27px !important;
  }
}

@media screen and (max-width:768px) {
  .management-wrapper{
    overflow-y: scroll;
  }
}

@media screen and (max-width:414px) {
 .management-wrapper{
   .table-container{
     top: 0px !important;
     right: 0px;
   }
 }
  .user-managment-container{
    padding: 0 !important;
  }
  .starter-page{
    overflow-y: scroll;
    padding: 0px 15px;
  }
  
}