.audit-label {
  padding: 10px 0px;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #3D425B;
}
.header-wrapper{
  display: flex;
  justify-content: space-between;
  // margin-top: 2.375rem;
  // padding-top: 30px;
  padding-left: 15px;
}
.audit-closeIcon{
  float: right;
  position: relative;
  // top: 10px;
  bottom: 29px;
}
.audit-wrapper{
  .audit-search{
  width: 100% !important;
  margin-left: 0px;
  padding: 15px;
  // width: 321px !important;
  }
}
.search-wrapper{
  position: relative;
  height: 42px;
}
.search-menu-icon{
  position: absolute;
  top: 7px;
  left: 10px;
  color: #3D425B;
  margin-left: 7px;
}

.audit-search{
  &::placeholder{
    position: absolute;
    font-family: "Roboto";
    left: 35px ;
    top: 10px;
  }
}

.audit-wrapper{
  overflow-y: scroll;
  height: 80%;
}
.search-wrapper>.qtextinput-wrapper{
  width: 100%;
}

@media screen and (max-width:1366px) {
  .audit-search{
    // width: 321px;
    width: 100%;
  }
}

@media screen and (min-width:1367px) {
  .audit-wrapper{
    .audit-search{
      // width: 321px !important;
      width: 100%;
    }
  }
}

@media screen and (max-width:768px) {
  .audit-closeIcon{
    float: right;
    bottom: 40px;
  }
  .audit-wrapper{
    // overflow-y: unset !important;
    height: 80vh;
    overflow-y: scroll;
  }
  .search-menu-icon{
    left: 10px !important;
  }
  .header-wrapper{
    padding-top: 15px;
  }
}


@media screen and (max-width:414px) {
  .audit-wrapper{
    height: 80vh;
    overflow-y: scroll;
  }
  .audit-search{
    width: 100% !important;
    }
    .search-menu-icon{
      // top: 30px;
      left: 10px !important;
    }
    .audit-closeIcon{
      // top: 30px;
      bottom: 39px;
      // float: right;
    }
    .header-wrapper{
      padding-top: 10px;
    }
}