.qaccordion-wrapper{
 .accordian-header{
     position: relative;
     font-weight: normal;
     font-size: 16px;
     line-height: 19px;
     color: #000000;
     background: #FBFBFB !important;
    border-radius: 4px;
    i{
        color: #2A74A5;    
    }
 }
 .card-body{
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3D425B;
    opacity: 0.6;
    padding-top: 5px;
 }

 .accordion .active .accordian-header i{
    transform: rotate(-180deg);
 }
 .accordian-header i{
     position: absolute;
     right:10px;
    transition: transform .2s ease-in-out;

 }
 .accordion .card {
     margin: 0px;
     box-shadow: none;
     background: #FBFBFB !important;
    border-radius: 4px;
    margin-bottom: 15px;
  
    .card-header{
        border-bottom: 0px solid #FBFBFB;
    }
 }
}