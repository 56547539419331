.list-group {
  max-height: 80vh;
  margin-bottom: 60px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.list-group-item.list-group-item {
  background: #fbfbfb;
  border-radius: 5px;
  padding: 1.625rem 0.938rem;
  margin-bottom: 15px;
  // box-shadow: 0px 4px rgba(218, 214, 214, 0.25);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  // filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
}
.list-label {
  font-size: 18px;
  line-height: 18px;
  color: #3d425b;
  font-weight: 400;
}
.infinite-scroll-component {
  // max-height: 34.75rem !important;
  //max-height: 80% !important;
  // min-height: calc(100vh-60px) !important;
  max-height: 80vh !important;
  overflow-y: scroll;
}

.qlist-wrapper {
    overflow-y: scroll;
}
// @media screen and (max-width:768px) {
//   .infinite-scroll-component {
//     max-height:47.75rem !important;
//   }
// }

// @media screen and (max-width:414px) {
//     .infinite-scroll-component{
//       max-height: 39.75rem !important;
//       // max-height: 70% !important;
//     }
//     .list-group{
//       margin-top: -20px;
//     }
// }