@import '../../../App.scss';


.main-header{
        // height: 11%;
        position: sticky;
        top: 0;
        z-index: 9;
        background:$linear-gradiant;
    
    .core-header{
        flex: 30;
        justify-content: flex-end;
        .dropdown-menu{
            position: absolute;
            // left: -110px;
        }
    }
    .toggle-sidebar{
        color: $white;
        cursor: pointer;
        font-size: 28px;
        font-weight: bold;
        &:hover{
            color: $dark-gray;
        }
    }
    
    header .navbar .container{
        max-width: 100vw;
    }
    .user-label{
        color: $primary-color;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }
}
.logout-container{
    background-color: #eeeeee;
    height:60px;
    width: 100px;
    border-radius: .25rem !important;
    left: 0;
   
}
.logout-text{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3D425B !important;
    text-decoration: none !important;
}
.dropdown-menu {
    // position: relative;
    border-radius: 10px !important;
    width: 100px;
    min-width:100px !important;
    height: 50px;
    right: 5px !important;
    left: unset !important;
    top: 40px !important;
    .dropdown-item:hover{
        background-color: #ffffff;
        color: #3D425B ;
    }
    .dropdown-item a{
        color: #3D425B;
    }

}
@media (max-width:768px) {
    .toggle-sidebar{
        display: block;
    }  
}
@media (min-width:770px) {
    .toggle-sidebar{
        display: none !important;
    }
}
