.starter-page {
  height: 80vh;
  .page-title {
    font-weight: bold;
    font-size: 22px !important;
    line-height: 26px !important;
    color: #3d425b !important;
    padding-left: 12px;
  }
}

@media screen and (max-width:1366px) {
  .starter-page{
    overflow-y: unset;
    .page-title{
      padding-left: 11px; 
    }
  }
  .starter-title{
    margin-left: -12px !important;
  }
}


@media screen and (max-width:768px) {
  .starter-page{
    overflow-y: unset;
    .page-title{
      padding-left: 11px; 
    }
  }
  .starter-title{
    margin-left: -12px !important;
  }
}


@media screen and  (max-width:415px) {
 .starter-page .starter-container{
    padding: 0px 11px !important;
  }
  .starter-page{
    overflow-y: unset;
    .page-title{
      padding-left: 0px; 
    }
  }
  .starter-title{
    margin-left: 0px !important;
  }
}
