.qtoast-wrapper {
  position: absolute;
  top: 0;
  z-index: 10;
  text-align: center;
  .toast {
    height: 50px !important;
    border-radius: 0px 0px 10px 10px;
  }
}
