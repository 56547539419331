@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import '~bootstrap/scss/bootstrap.scss';

.no-padding {
    padding: 0 !important;
}
@font-face {
    font-family: Roboto;
    src: url(../src/assets/fonts/Roboto-Regular.ttf);
}
body {
    font-family: 'Roboto' !important;
}

html, body, #root, .App, .full-height, .main-section {
    height: 100%;
    width: 100%;
    // min-height: 100vh ;
    // overflow: auto;

    ::-webkit-scrollbar{
        width:0;
    }
}
.full-height{
    margin: 0 !important;
}

.main-section {
    background-color: white;
    height: 100%;
    position: relative;
}
.main-section::-webkit-scrollbar{
    display: none;
}

.dropdown-toggle::after {
    vertical-align: 0;
}

.metismenu-link.active {
    background: unset !important;
}

.metismenu-link.has-active-child {
    color: unset !important;
}

a:hover {
    text-decoration: unset !important;
}

.App{
    // overflow-y: scroll;
    a.text-muted, span.text-muted{
        cursor: pointer;
    }
}
.App::-webkit-scrollbar {
    display: none;
}

@include media-breakpoint-up(lg) { 
    .App .row.root-container{
        flex-wrap: nowrap;
    }
}

@include media-breakpoint-up(xl) { 
    .App .row.root-container{
        flex-wrap: nowrap;
    }
}


.cursor-pointer{
    cursor: pointer;
}
.modal-open {
    padding-right: 0 !important;
}



$dark-bg: #303335;
$light-color: rgba(255,255,255,.5);
$black-squeeze: #f7fafc;
$white: #fff;
$light-gray: rgba(0,0,0,.5);
$dark-gray: #1d1d1d;
$delicate-gray: rgba(0,0,0,.125);
$primary-color:#2A74A5;
$bulk-delete-color:#6C6D75;
$linear-gradiant: linear-gradient(90.42deg, #0A619A 0.37%, #FFFFFF 107.24%);
$table-color:#FBFBFB;
$success:#2AA52F;
$toast-color:#F6FFF7;
$primary-text-color:#3D425B;
$sidebar-width:200px;


.box-container{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}
.spinner-border{
    position: absolute;
    z-index: 2000;
    left: 50%;
    top: 50%;
    height: 4rem !important;
    width: 4rem !important;
}
#show-toast{
    display: flex;
    justify-content: center;
    .error-toast{
        background:#FFC7EF;
        // height: 50px;
        padding: 11px;
        text-align: center;
        border-radius: 0px 0px 10px 10px;
    }
    
}
.loader-modal{
    .modal-content{
        background-color: transparent;
    }
}
.spinner-wrapper{
    position:fixed;
    inset: 0;
    display: grid;
    place-content: center;
    .spinner-border{
        position: static !important;
    }
}
