$linear-gradiant: linear-gradient(90.42deg, #0A619A 0.37%, #FFFFFF 107.24%);
$primary-color: #2a74a5;
$white: #fff;

.landing-page{
    .header{
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 50px;
        width: 100%;
        background: $linear-gradiant;
        .login-logo{
           height: 40px;
           width: 95px;
        }
    }
    .landing-footer{
        height: 20px;
        width: 100%;
        background:$linear-gradiant
    }
    .landing-body{
        display: flex;
        align-items: center;
        .login-content{
            width: 40%;
            display: flex;
            flex-direction: column;
            padding-left: 100px;
            .heading{
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 47px;
                color: #3D425B;
                margin-bottom: 15px;
            }
            .subtext{
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 28px;
                color: #666666;
                margin-bottom: 60px;
            }
            .login-btn{
                height: 42px !important;
                background: $primary-color;
                color: $white;
                border-radius: 0.25rem; 
                width: 150px;
                font-weight: bold;
            }
        }
        .login-bg{
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .login-bg-image{
                width: 713px;
                height: 374px;
                @media (max-width: 1202px) {
                    width: 650px;
                    height: 300px;
                  }
            }
            .login-bg-text{
                font-style: normal;
                font-weight: normal;
                font-size: 32px;
                line-height: 28px;
                color: #0177BD;
                margin-top: 20px;
            }
        }
        
    }
    .landing-version{
        font-size: 9px;
        position: absolute;
        top: 94%;
        right: 5px;
    }    
    @media (max-width: 1024px) {
        .landing-body{
            height: 100%;
            flex-direction: column-reverse;
            align-items:flex-start;
            .login-content{
                width: 602px;
                height: 50%;
                .subtext{
                   margin-bottom: 40px;
                }
            }
            .login-bg{
               width: 100%;
               height: 50%;
               margin: 80px 0px 40px 0px;
            }  
        }
      }
      @media (max-width: 768px) {
        .landing-body{
            width: 100%;
            .login-content{
                width: 402px;
                height: 50%;
                padding-left: 36px;
                .heading{
                    margin-bottom: 13px;
                }
                .subtext{
                   margin-bottom: 76px;
                }
            }
            .login-bg{
               width: 100%;
               height: 50%;
               margin: 80px 0px 40px 0px;
               padding: 0px 76px 0px 76px;
                .login-bg-image{
                    width: 100%;
                    height: 28.5vh;
                }
            }
            
        }
      }
      @media (max-width: 414px) {
          .header{
            padding-left: 15px;
          }
        .landing-body{
            .login-content{
                width: 100%;
                height: 50%;
                padding-left: 30px;
                padding-right: 30px;
                .heading{
                    font-size: 30px;
                    line-height: 35px;
                    margin-bottom: 15px;
                }
                .subtext{
                    font-size: 18px;
                    line-height: 21px;
                    margin-bottom: 40px;
                }
                .login-btn{
                  width: 100%;
                }
            }
            .login-bg{
               width: 100%;
               height: 50%;
               margin: 60px 0px 40px 0px;
               padding: 0px 43px 0px 43px;
                .login-bg-image{
                    width: 328.42px;
                    height: 194.08px;
                }
                .login-bg-text{
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            
        }
      }
}