@import "../../../App.scss";

aside {
  background: $white;
  color: $light-color;
  height: 100%;
}

.navbar-brand {
  margin: 0 10px;
}

.pro-sidebar .pro-menu a.navbar-brand {
  overflow-wrap: break-word;
  white-space: break-spaces;
}

.dropdown {
  display: inline-block;
}
header {
  a.nav-link {
    padding-left: 0 !important;
  }
}
@include media-breakpoint-up(sm) {
  .pro-sidebar.app-sidebar.sm {
    left: 0;
  }
}
@include media-breakpoint-up(md) {
  .pro-sidebar.app-sidebar.md {
    left: 0;
  }
}

ul span {
  margin: 0;
}

.pro-sidebar > .pro-sidebar-inner {
  background: $white !important;
}
.pro-item-content {
  border-left: 4px solid transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0 !important;
  height: 42px;
}

.active > .pro-inner-item > .pro-item-content {
  border-left: 4px solid $primary-color !important;
  z-index: 1;
  background: linear-gradient(
    to left,
    rgba(137, 179, 212, 0),
    rgba(138, 162, 243, 0.486)
  );
  .svg-icon {
    fill: $primary-color;
  }
  a:hover {
    color: $primary-color !important;
  }
}
.active > .pro-inner-item > .pro-item-content > a {
  color: $primary-color;
}

.svg-icon {
  margin: 0.7rem 0.7rem;
}
.pro-sidebar > .pro-sidebar-inner {
  border-right: solid 0.1rem rgba(#000, 0.2);
}
.pro-sidebar .pro-menu a:hover {
  color: #8b8e9d !important;
}
.pro-sidebar {
  width: $sidebar-width !important;
  min-width: $sidebar-width !important;
}
.logo-container {
  display: flex !important;
  justify-content: center;
  margin-bottom: 24px;
  img{
    aspect-ratio: 2/1;
  }
}
.hide-sidebar {
  display: none;
}
.toggled {
  display: block;
}

.menu-version{  
  font-size: 9px;
  position: absolute;
  top: 98%;
  left: 90px;
}  

// .pro-menu-item>.pro-inner-item>.pro-item-content{
//     border-left: 0px solid rgba(255,255,255,.1);
//     overflow: hidden;
//     z-index: -1;
// }
.url-config-admin{
//   font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  //   margin-left: 10px;
  position: relative;
  left: 7%;
  color: #3d425b !important;
  cursor: none !important;
  .pro-inner-item{
     color: #3d425b !important;
     cursor: default !important;
 }
}


@media (max-width: 768px) {
  .toggled {
    position: absolute !important;
    left: 0 !important;
    z-index: 50 !important;
  }
}

@media (min-width: 768px) {
  // .toggled {
  //   position: absolute !important;
  //   left: 0 !important;
  //   z-index: 50 !important;
  // }
  .main-section{
    // position: fixed !important;
  }
}
@media (min-width: 769px) {
  .hide-sidebar {
    display: block !important;
  }
  .main-section{
    width: calc(100% - 200px) !important;
    // position: relative !important;
  }
}
